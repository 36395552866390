<template>
  <div id="details">
    <!-- 头部 -->
    <MyMenu :menuname="'index'"></MyMenu>
    <!-- 头部END -->

    <!-- 主要内容 -->
    <div class="main">
      <!-- 左侧商品轮播图 -->
      <div class="block">
        <el-carousel class="blockH" v-if="productPicture.length > 1">
          <el-carousel-item v-for="item in productPicture" :key="item.id">
            <img style="height:100%;" :src="item" :alt="item" />
          </el-carousel-item>
        </el-carousel>
        <div v-if="productPicture.length == 1">
          <img style="height:100%;" :src="productPicture" :alt="productPicture" />
        </div>
      </div>
      <!-- 左侧商品轮播图END -->

      <!-- 右侧内容区 -->
      <div class="content">
        <h1 class="name">
          <span>{{ productDetails.goodsCondition }}</span>
          {{ productDetails.title }}
        </h1>
        <p class="intro">{{ productDetails.product_intro }}</p>
        <p class="store">{{ productDetails.primaryClassifyName }}/{{ productDetails.secondaryClassifyName }}</p>
        <div class="price">
          <span>
            月租金：
            <b>{{ ActivityMonthRent }}</b>
            元/月
          </span>
          <span class="priceInfo">
            <span>设备押金:</span>
            ￥
            <i>{{ productDetails.cashPledge }}</i>
          </span>
        </div>
        <div class="pro-list">
          <p>选择配置：</p>
          <ul>
            <li v-for="(item, index) in specCombinationList" :key="index" :class="specCombinationstyle === index ? 'item_select' : 'item_nomal'" @click="currentSelect(item, index)">{{ item.propertiesShow }}</li>
          </ul>
        </div>
        <div class="pro-list">
          <p>服务方式：</p>
          <ul>
            <li v-for="(item, index) in modelList" :key="index" :class="leasingstyle === index ? 'item_select' : 'item_nomal'" @click="monthSelect(item, index)">
              <div>
                <span class="tag">{{ item.isExpirePresented ? '租完即送' : '租后归还' }}</span>
                <div class="rent">
                  <div>
                    月租金：
                    <span class="red">￥{{ item.rent }}</span>
                    租期：
                    <span class="red">{{ item.leaseDuration }}</span>
                    个月
                  </div>
                </div>
              </div>
              <div class="center" v-if="!item.isExpirePresented">到期归还，租满{{ item.exemptionMonth }}个月后可随时退，未满{{ item.exemptionMonth }}个月退还需缴纳服务费</div>
              <div class="bottom">
                <span v-if="![12, 24, 36].includes(item.leaseDuration)">1~{{item.leaseDuration}}期：{{item.rent_1_12}}元/月;</span>
                <span v-if="[12, 24, 36].includes(item.leaseDuration)">1~12期：{{ item.rent_1_12 }}元/月；</span>
                <span v-if="[24, 36].includes(item.leaseDuration)">13~24期：{{ item.rent_13_24 }}元/月；</span>
                <span v-if="[36].includes(item.leaseDuration)">25~36期：{{ item.rent_25_36 }}元/月；</span>
              </div>
            </li>
          </ul>
        </div>

        <!-- <div class="pro-list" v-if="checkSKUList.length && checkSKUList.length">
          <p> 已选: "<span v-for="(item, i) in checkSKUList" :key="i">{{item}}</span> "</p>
         
        </div> -->

        <!-- 内容区底部按钮 -->
        <div class="button">
          <!-- <el-button class="shop-cart" :disabled="dis" @click="addShoppingCart">加入购物车</el-button> -->
          <!-- <el-button class="like" @click="addCollect">收藏</el-button> -->
          <el-button class="shop-cart" @click="ConfirmOrder">立即下单</el-button>
          <!-- <el-button class="like" @click="contact">联系客服</el-button> -->
          <el-button class="like" @click="showOverlay(productDetails.zfbcommodityUrl)">微信扫码下单</el-button>
        </div>
        <!-- 内容区底部按钮END -->
        <div class="pro-policy">
          <ul>
            <li>
              <i class="el-icon-circle-check"></i>
              巨悠自营
            </li>
            <li>
              <i class="el-icon-circle-check"></i>
              信用免押
            </li>
            <li>
              <i class="el-icon-circle-check"></i>
              即时发货
            </li>
          </ul>
        </div>
      </div>
      <!-- 右侧内容区END -->
    </div>
    <!-- 主要内容END -->

    <!--微信扫码 -->
    <div class="overlay" :class="{ active: overlayActive }" @click="hideOverlay">
      <span><img :src="zfburl" /></span>
    </div>

    <!-- 底部参数介绍 -->

    <div class="parameter">
      <a id="mao"></a>
      <el-tabs v-model="activeName">
        <el-tab-pane label="商品详情" name="1"><img mode="widthFix" :lazy-load="true" :src="productDetails.commodityDetails" /></el-tab-pane>
        <el-tab-pane label="租赁说明" name="2"><img mode="widthFix" :lazy-load="true" :src="productDetails.leaseShow" /></el-tab-pane>
        <el-tab-pane label="租后说明" name="3"><img mode="widthFix" :lazy-load="true" :src="productDetails.specificationParameter" /></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      dis: false, // 控制“加入购物车按钮是否可用”
      commodityId: '', // 商品id
      productDetails: '', // 商品详细信息
      productPicture: '', // 商品图片
      specCombinationList: [], //SKU列表
      modelList: [], //模式列表
      leasingstyle: 0, //租期索引
      specCombinationstyle: 0, //配置展示索引
      currentCommoditySkuId: '', //skuid
      leasePatternId: '', //模式id
      ActivityMonthRent: '', //展示月租金
      activeName: '1',
      zfburl: '',
      overlayActive: false,
      currentCommodityDetail: {}
    }
  },
  computed: {},
  created() {
    if (this.$route.query.commodityId != undefined) {
      this.commodityId = this.$route.query.commodityId
    }
  },
  onload() {},

  watch: {
    // 监听商品id的变化，请求后端获取商品数据
    commodityId: function(val) {
      this.getDetails(val)
      this.getcommoditySku(val)
      this.activeName = '1'
      ;(this.leasingstyle = 0), //租期索引
        (this.specCombinationstyle = 0) //配置展示索引
      this.zfburl = ''
      this.backtop()
    }
  },
  methods: {
    ...mapActions(['setcurrentCommodityDetail']),
    // 获取商品详细信息

    getDetails(val) {
      this.$axios
        .get('/zuwu-api/official/officialCommodityDetail?commodityId=' + val, {})
        .then((res) => {
          if (res.data.code == 200) {
            this.productDetails = res.data.data
            this.productPicture = res.data.data.cycleImages.split(',')
            this.ActivityMonthRent = res.data.data.minimumRent
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error('网络错误')
        })
    },

    // 获取商品sku
    getcommoditySku(val) {
      this.$axios
        .get('/zuwu-api/official/skuList?commodityId=' + val, {})
        .then((res) => {
          if (res.data.code == 200) {
            this.specCombinationList = res.data.data
            this.currentCommoditySkuId = this.specCombinationList[0].commoditySkuId
            this.getcommoditySkuList(this.currentCommoditySkuId)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error('网络错误')
        })
    },

    // 获取商品模式列表
    getcommoditySkuList(val) {
      this.$axios
        .get('/zuwu-api/official/commoditySkuList?commoditySkuId=' + val, {})
        .then((res) => {
          if (res.data.code == 200) {
            this.modelList = res.data.data
            this.leasePatternId = this.modelList[this.leasingstyle].leasePatternId
            this.getdetailByLeasePatternId()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error('网络错误')
        })
    },

    // 选择配置
    currentSelect(val, index) {
      this.specCombinationstyle = index
      this.currentCommoditySkuId = val.commoditySkuId
      this.getcommoditySkuList(val.commoditySkuId)
    },

    // 选择租期
    monthSelect(val, index) {
      this.leasingstyle = index
      this.leasePatternId = val.leasePatternId
      this.getdetailByLeasePatternId()
    },

    // 根据skuid和模式id查询价格
    getdetailByLeasePatternId() {
      this.$axios
        .get('/zuwu-api/official/detailByLeasePatternId?leasePatternId=' + this.leasePatternId + '&skuId=' + this.currentCommoditySkuId, {})
        .then((res) => {
          if (res.data.code == 200) {
            this.currentCommodityDetail = res.data.data
            this.ActivityMonthRent = this.currentCommodityDetail.rent
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error('网络错误')
        })
    },

    // 微信
    showOverlay(url) {
      this.zfburl = url
      this.overlayActive = true
    },
    hideOverlay() {
      this.overlayActive = false
    },

    // 联系我们
    contact() {
      window.open(`https://cschat.antcloud.com.cn/index.htm?tntInstId=osv_cVM6&scene=SCE01228211`)
    },

    // 返回顶部
    backtop() {
      const timer = setInterval(function() {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        const speed = Math.floor(-top / 5)
        document.documentElement.scrollTop = document.body.scrollTop = top + speed

        if (top === 0) {
          clearInterval(timer)
        }
      }, 20)
    },

    ConfirmOrder() {
      this.getCheckNum = 1
      this.currentCommodityDetail.title = this.productDetails.title
      // 商品详情存储
      localStorage.setItem('currentCommodityDetail', JSON.stringify(this.currentCommodityDetail))
      // 登录信息存到vuex
      // this.setcurrentCommodityDetail(JSON.stringify(this.currentCommodityDetail));
      this.$router.push({ path: '/ConfirmOrder', query: { search: this.search } })
    },

    // 加入购物车
    addShoppingCart() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.router.push({ path: '/login' })
        return
      }
      this.$axios
        .post('/api/user/shoppingCart/addShoppingCart', {
          user_id: this.$store.getters.getUser.user_id,
          product_id: this.productID
        })
        .then((res) => {
          switch (res.data.code) {
            case '001':
              // 新加入购物车成功
              this.unshiftShoppingCart(res.data.shoppingCartData[0])
              this.notifySucceed(res.data.msg)
              break
            case '002':
              // 该商品已经在购物车，数量+1
              this.addShoppingCartNum(this.productID)
              this.notifySucceed(res.data.msg)
              break
            case '003':
              // 商品数量达到限购数量
              this.dis = true
              this.notifyError(res.data.msg)
              break
            default:
              this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    addCollect() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.router.push({ path: '/login' })
        return
      }
      this.$axios
        .post('/api/user/collect/addCollect', {
          user_id: this.$store.getters.getUser.user_id,
          product_id: this.productID
        })
        .then((res) => {
          if (res.data.code == '001') {
            // 添加收藏成功
            this.notifySucceed(res.data.msg)
          } else {
            // 添加收藏失败
            this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }
  }
}
</script>
<style scoped>
@import '../../assets/css/index.css';
</style>
